import React from "react";
import { Navigation } from "../src/components/navigation/index";
import { Fade } from "@material-ui/core";
import "./App.css";
import ReactGA from "react-ga4";
require('dotenv').config();
ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_MEASUREMENT_ID
  }
]);

function App() {
  return (
    <>
      <Fade in={true} timeout={1500}>
        <Navigation />
      </Fade>
    </>
  );
}
export default App;
