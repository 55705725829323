import { authenticateRequest } from "./helpers"

export const fetchPortfolioData = async () => {
  const auth = await authenticateRequest()
  if (auth.user) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/portfolio.json`)
      const result = await response.json()
      return result
    } catch (err) {
      console.error('There was an error fetching portfolio data', err)
    }
  }
  return undefined
}
