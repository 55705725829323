import React, { useState } from 'react'
import { CircleLoader } from 'react-spinners'

export const Image = (props) => {
  const { item, size, className } = props
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const handleImageLoad = () => {
    setIsImageLoaded(true)
  }
  return (
    <>
      {
        !isImageLoaded && 
        <div className={`spinner-container ${className}`}>
          <CircleLoader
            size={size}
            width={20}
            radius={20}
            margin={20}
            color={"rgb(119, 189, 189)"} />
        </div>
      }
      {
        item.nda ?
        <img 
          src={`${process.env.REACT_APP_S3_BUCKET_URL}/images/NDA.webp`}
          className="project-photo" 
          alt="NDA"
          onLoad={handleImageLoad}
          style={{ display: isImageLoaded ? 'block' : 'none' }}
        />
        :
        <img
          src={`${process.env.REACT_APP_S3_BUCKET_URL}/${item.image}`}
          className="project-photo"
          alt={item.name}
          id={item.id}
          onLoad={handleImageLoad}
          style={{ display: isImageLoaded ? 'block' : 'none' }}
        />
      }
    </>
  )  
}

