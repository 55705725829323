import {
  Button,
  Card,
  Typography,
  Fab,
  useScrollTrigger,
  Zoom,
  makeStyles,
  Fade,
} from "@material-ui/core";
import { Code, OpenInNew, KeyboardArrowUp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import "./Portfolio.css";
import Carousel from "react-material-ui-carousel";
import Logo from "../shared/Logo";
import { useAppContext } from "../../AppContext";
import { Loading } from "../loading";
import { Image } from "../shared/Image";
import { useAnalyticsEventTracker } from '../../helpers';
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: "100px",
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}
export const Portfolio = (props) => {
  const gaEventTracker = useAnalyticsEventTracker('Portfolio Page');
  const [appStore,] = useAppContext();
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (appStore.projects.length) {
      setLoading(false)
    }
  }, [appStore.projects])

  return (
      loading ?
      <Loading />
      :
      <>
      <div id="portfolio-container">
        <Typography
          className="text-white portfolio-heading"
          variant="h2"
          style={{
            marginBottom: "10px",
          }}
        >
          Portfolio
        </Typography>
        <div id="portfolio-carousel-container">
          <Carousel
            autoPlay={false}
            id="portfolio-carousel"
            animation={"slide"}
            timeout={750}
            swipe={true}
            navButtonsAlwaysVisible={true}
            index={0}
            onChange={(index) => setIndex(index)}
            onClick={() => gaEventTracker('Carousel interaction')}
          >
            {appStore.projects && appStore.projects.map((item) => {
                  return (
                      <div key={`${item.name}-card`} className="photo-container" >
                        <Card
                          className="carousel-item"
                        >
                          <Image item={item} size={200} className="portfolio-image" />
                        </Card>
                      </div>
                  );
                })}
          </Carousel>
          {index >= 0 ? (
              <Fade
                in={true}
                timeout={{
                  appear: 500,
                  exit: 250,
                  enter: 250,
                }}
                exit={true}
                enter={true}
              >
                <div className="info-container">
                  <Typography variant="h4" className="card-title text-white">
                    {appStore.projects && appStore.projects[index].name}
                  </Typography>
                  <div className="paragraph-container">
                    {
                     appStore.projects && appStore.projects[index].description
                      .split("  ")
                      .map((paragraph, index) => {
                        return (
                          <div key={`paragraph-${index}`}>
                            <Typography className="paragraph text-white">
                              {paragraph}
                            </Typography>
                          </div>
                        );
                      })}
                  </div>
                  <div className="stack-container text-white">
                    <Typography
                      variant="h5"
                      style={{
                        marginBottom: "5px",
                        overflowWrap: "break-word"
                      }}
                    >
                      Languages / Frameworks / Platforms:
                    </Typography>
                    <div id="logo-container">
                      {
                        appStore.projects[index].stack.map((logo) => {
                          return (
                            <div key={`${logo}-img`}>
                              <Logo size={40} filename={`${logo}.webp`} className="portfolio-logo" />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className="button-container">
                    {appStore.projects[index].sourcecode ? (
                      <a
                        href={appStore.projects[index].sourcecode}
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                        rel="noreferrer noopener"
                      >
                        <Button
                        onClick={() => gaEventTracker(`${appStore.projects[index].name} Source Code button click`)}
                          variant="contained"
                          className="button">
                          <Code
                            style={{
                              marginRight: "3px",
                            }}
                          />
                          Source Code
                        </Button>
                      </a>
                    ) : null}
                    {appStore.projects[index].url ? (
                      <a
                        href={appStore.projects[index].url}
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                        rel="noreferrer noopener"
                      >
                        <Button
                          onClick={() => gaEventTracker(`${appStore.projects[index].name} Website button click`)}
                          variant="contained"
                          className="button">
                          <OpenInNew
                            style={{
                              marginRight: "3px",
                            }}
                          />
                          Website
                        </Button>
                      </a>
                    ) : appStore.projects[index].nda ?
                    <Typography>
                      Due to a Non-Disclosure agreement, access to this site is prohibited.
                    </Typography>
                    : null}
                  </div>
                </div>
              </Fade>
          ) : null}
        </div>
      </div>

      <ScrollTop {...props}>
        <Fab color="default" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
      </>
  );
};
