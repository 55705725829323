import React from "react";
import { Grow } from "@material-ui/core";
import { GitHub, LinkedIn } from "@material-ui/icons";
import "./Footer.css";
import { useAnalyticsEventTracker } from '../../helpers';

export const Footer = () => {
  const gaEventTracker = useAnalyticsEventTracker('Footer');
  return (
    <>
      <div id="phantom">
        <div id="footer">
          <Grow in={true} timeout={3000}>
            <a
              size="large"
              href="https://github.com/kevinwgrove"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => gaEventTracker('GitHub Footer click')}
            >
              <GitHub className="footer-button" />
            </a>
          </Grow>
            <Grow in={true} timeout={3000}>
              <a
                size="large"
                href="https://www.linkedin.com/in/kevin-grove-249247139/"
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => gaEventTracker('LinkedIn Footer click')}
              >
                <LinkedIn className="footer-button" />
              </a>
            </Grow>
            <Grow in={true} timeout={3000}>
              <a
                size="large"
                href="https://gitlab.com/kevinwgrove"
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => gaEventTracker('GitLab Footer click')}
              >
                <img
                  src={`${process.env.REACT_APP_S3_BUCKET_URL}/images/Gitlab-Icon.png`}
                  alt="Gitlab Icon"
                  width="28px"
                  className="footer-button"
                />
              </a>
            </Grow>
        </div>
      </div>
    </>
  );
};
