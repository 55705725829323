import React, { useEffect, useState } from "react";
import { Button, Typography, Grow, IconButton, Fade } from "@material-ui/core";
import { Close, Email } from "@material-ui/icons";
import "./Home.css";
import { withRouter } from "react-router-dom";
import Logo from "../shared/Logo";
import { useAppContext } from "../../AppContext";
import { Loading } from "../loading";
import { CircleLoader } from "react-spinners";
import { useAnalyticsEventTracker } from '../../helpers';

export const Home = () => {
  const [contact, setContact] = useState(false)
  const [appStore] = useAppContext();
  const [loading, setLoading] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const gaEventTracker = useAnalyticsEventTracker('Home Page');

  useEffect(() => {
    if (appStore.logos.length) {
      setLoading(false)
    }
  }, [appStore.logos])

  const sendEmail = () => {
    const link = "mailto:kevinwilsongrove@gmail.com";
    window.location.href = link;
  };
  
  const handleImageLoad = () => {
    setIsImageLoaded(true)
  }
  
  return (
    loading ? 
      <Loading />
     : 
    <>
      <div
        id="home-container"
        className="text-white"
        onClick={() => {
          if (contact) {
            setContact(false);
          }
        }}
      >
        <div className="info">
          <div id="text-container-home">
            <Typography className="roboto first-line text-home">
              Hi, my name is
            </Typography>

            <Typography
              className="name-home nunito text-home w3-animate-opacity"
              style={{
                fontSize: "110px",
              }}
            >
              Kevin Grove
            </Typography>

            <div id="paragraph-container">
              <Typography className="noto-sans paragraph-home text-home">
                I'm a software engineer based in Indianapolis, IN. I specialize
                in building applications and websites as a single/multi page
                application. Some languages and frameworks I've built with, but
                not limited to:
              </Typography>
            </div>
            <div id="logo-container">
              {
                appStore.logos.map((logo, index) => {
                  return (
                    <Fade key={`${logo['image']}-fade`} timeout={index * 250} in={true}>
                      <Logo size={20} filename={logo['image']} folder={'logo-svgs-thumbs'} />
                    </Fade>
                  )
                })
              }
            </div>
          </div>
          <div id="img-div">
            {
              !isImageLoaded && 
              <div className="spinner-container">
                <CircleLoader 
                size={280}
                width={20}
                radius={20}
                margin={20}
                color={"rgb(119, 189, 189)"} />
              </div>
            }
            <Fade timeout={5000} in={true}>
              <img
                src={`${process.env.REACT_APP_S3_BUCKET_URL}/images/Palladium.webp`}
                alt="Kevin Grove"
                id="home-photo"
                onLoad={handleImageLoad}
                style={{ display: isImageLoaded ? 'block' : 'none' }} />
            </Fade>
          </div>
        </div>
        <div id="contact-button-container">
          {!contact ? (
            <Button
              className="text-white button-home"
              variant="contained"
              onClick={() => {
                gaEventTracker('Get In Touch button click')
                setContact(true)
              }}
            >
              Get In Touch
            </Button>
          ) : (
            <IconButton
              className="text-white button-home close-button"
              variant="contained"
              onClick={() => {
                gaEventTracker('Get In Touch close button click')
                setContact(false)
              }}
            >
              <Close/>
            </IconButton>
          )}
        </div>
        {contact ? (
          <>
            <Grow in={true} timeout={500}>
              <div id="phantom-buttons-container">
                <Button
                  className="text-white phantom-button"
                  variant="contained"
                  onClick={() => {
                    gaEventTracker('Get In Touch email opened')
                    sendEmail()
                  }}
                >
                  <Email
                    style={{
                      fontSize: "300%",
                    }}
                  />
                </Button>
              </div>
            </Grow>
          </>
        ) : null}
      </div>
    </>
  );
};
export default withRouter(Home);
