import React, { useState } from "react";
import { CircleLoader } from "react-spinners";

export const Logo = (props) => {
  const { filename, className, size, folder='logo-svgs' } = props
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const logoName = filename.split(".")[0]
  const handleImageLoad = () => {
    setIsImageLoaded(true)
  }
  return (
    <div className="logo-container">
      {
        !isImageLoaded && 
        <div className="spinner-container">
          <CircleLoader
          size={size}
          width={20}
          radius={20}
          margin={20}
          color={"rgb(119, 189, 189)"} />
        </div>
      }
      <img
      src={`${process.env.REACT_APP_S3_BUCKET_URL}/images/${folder}/${filename}`}
      className={`${className} logo`}
      alt={logoName}
      title={logoName}
      onLoad={handleImageLoad}
      style={{ display: isImageLoaded ? 'block' : 'none' }} />
    </div>
  )
}

export default Logo
