import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchPortfolioData } from "./helpers/api-calls";

const initialAppState = {
  logos: [],
  projects: []
}

const AppContext = createContext(initialAppState);

export const AppProvider = ({ children }) => {
  const [appStore, setAppStore] = useState(initialAppState);
  useEffect(() => {
    const setProjectsAndImages = async () => {
      try {
        // let portfolioData = JSON.parse(localStorage.getItem("portfolioData")) 
        // if (!portfolioData) {
        //   localStorage.setItem("portfolioData", JSON.stringify(portfolioData)) 
        //   console.log('Successfully fetched from Google SQL backend')
        // }
        const portfolioData = await fetchPortfolioData()
        setAppStore(portfolioData)
      } catch (err) {
        console.error('There was an error fetching from Google SQL backend', err)
      }
    }
    setProjectsAndImages()
  }, [])
  return (
    <AppContext.Provider value={[appStore, setAppStore]}>
      {children}
    </AppContext.Provider>
  );
};
export const useAppContext = () => {
  const [appStore, setAppStore] = useContext(AppContext)
  return [appStore, setAppStore, initialAppState]
};
