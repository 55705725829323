import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  Fade,
  Grow,
  TextField,
  Typography,
  makeStyles,
  Button,
  Fab,
  useScrollTrigger,
  Zoom,
} from "@material-ui/core";
import { KeyboardArrowUp } from "@material-ui/icons";
import "./Contact.css";
import PopupMessage from "../shared/PopupMessage";
const useStylesCustom = makeStyles((theme) => ({
  root: {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fff",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      color: "#000",
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  name: {
    margin: "10px",
    flexGrow: "1",
    zIndex: "0",
  },
  email: {
    margin: "10px",
    width: "70%",
    zIndex: "0",
  },
  subject: {
    margin: "10px",
    flexGrow: "1",
    zIndex: "0",
  },
  message: {
    margin: "10px",
    flexGrow: "1",
    zIndex: "0",
  },
  button: {
    margin: "10px",
    width: "30%",
    zIndex: "0",
  },
  topAnchor: {
    position: "fixed",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
function CustomTextField(props) {
  const classes = useStylesCustom();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}
function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.topAnchor}
      >
        {children}
      </div>
    </Zoom>
  );
}
export const Contact = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.TEMPLATE_ID, e.target, process.env.USER_ID).then(
      (result) => {
        setMessage("Your message has been sent. I will get back to you as soon as I can. Thank you! -Kevin");
        setSeverity("success");
        setOpen(true);
      },
      (error) => {
        setMessage("There was a problem with your request, please reach out to me directly at kevinwilsongrove@gmail.com. Thank you!");
        setSeverity("error");
        setOpen(true);
        console.log(error.text);
      }
    );
    e.target.reset();
  }
  return (
    <>
      <div id="contact-container">
        <Fade in={true} timeout={1000}>
          <Typography
            className="text-white contact-heading"
            variant="h2"
            style={{
              marginBottom: "10px",
            }}
          >
            Contact Form
          </Typography>
        </Fade>
        <form className="form" onSubmit={sendEmail}>
          <div className="name-container">
            <Grow in={true} timeout={1000}>
              <CustomTextField
                label="First Name"
                className={classes.name}
                variant="filled"
                id="first-name"
                name="first-name"
                required
              />
            </Grow>
            <Grow in={true} timeout={1000}>
              <CustomTextField
                label="Last Name"
                className={classes.name}
                variant="filled"
                id="last-name"
                name="last-name"
                required
              />
            </Grow>
          </div>

          <div className="email-container">
            <Grow in={true} timeout={1500}>
              <CustomTextField
                label="Email"
                className={classes.email}
                variant="filled"
                id="email"
                name="email"
                required
              />
            </Grow>
          </div>
          <Grow in={true} timeout={2000}>
            <CustomTextField
              label="Subject"
              className={classes.subject}
              variant="filled"
              id="subject"
              name="subject"
              required
            />
          </Grow>
          <Grow in={true} timeout={2000}>
            <CustomTextField
              label="Message"
              className={classes.message}
              multiline
              minRows={10}
              variant="filled"
              id="message"
              name="message"
              required
            />
          </Grow>
          <div className="button-container">
            <Grow in={true} timeout={2500}>
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
              >
                Submit
              </Button>
            </Grow>
          </div>
          <PopupMessage open={open} message={message} severity={severity} setOpen={setOpen} />
        </form>
      </div>
      <ScrollTop {...props}>
        <Fab color="default" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
    </>
  );
};
