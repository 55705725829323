import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
require('dotenv').config();

const config = {
  apiKey: process.env.REACT_APP_GCP_API_KEY,
  authDomain: process.env.REACT_APP_GCP_AUTH_DOMAIN
};
firebase.initializeApp(config);

export const authenticateRequest = () => {
  const auth = firebase.auth().signInWithEmailAndPassword(process.env.REACT_APP_GCP_AUTH_EMAIL, process.env.REACT_APP_GCP_AUTH_PASSWORD).catch((e) => e)
  return auth
}
